export default {
  methods: {

    attachOpenListener () {
      document.addEventListener('click', this.onClick, false)
      document.addEventListener('keyup', this.onKeyUp, false)
    },
    detachOpenListener () {
      document.removeEventListener('click', this.onClick, false)
      document.removeEventListener('keyup', this.onKeyUp, false)
    },

    onKeyUp (event) {
      if (event.key === 'Escape') {
        this.onClick()
      }
    },

    onClick () {
      this.close()
    }

  },

  mounted () {
    this.attachOpenListener()
  },

  beforeDestroy () {
    this.detachOpenListener()
  }

}
