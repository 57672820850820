<template>
    <div class="relative w-full select-none top-controls">
  
      <div class="absolute top-0 z-10 flex justify-center w-full p-3">  
        <div class="flex flex-wrap content-start justify-center center-controls" @dblclick.prevent.stop>
          <div class="relative mx-1 cursor-pointer text-2xs" v-if="showBookmarkSwitcher">
            <div class="flex items-center justify-center w-48">
              <button @click.prevent.stop="toggleBookmarksListOrView" class="flex items-center justify-center flex-1 w-24 px-1 py-1 font-bold text-white truncate bg-gray-900 border-t border-b border-gray-700 cursor-pointer" :class="{ 'rounded-l' : hasMultipleBookmarks, 'rounded': !hasMultipleBookmarks, 'opacity-75': hasActiveBookmark, 'opacity-50 hover:opacity-75': !hasActiveBookmark }">
                 {{ lastActiveBookmarkName }}
              </button>
              <button v-if="hasMultipleBookmarks" @click.prevent="prevBookmark" class="px-2 py-1 font-bold text-white bg-gray-900 border border-gray-700 opacity-50 cursor-pointer text-2xs hover:opacity-75">
                <fa-icon icon="arrow-left" />
              </button>
              <button v-if="hasMultipleBookmarks" @click.prevent="nextBookmark" class="px-2 py-1 font-bold text-white bg-gray-900 border border-gray-700 rounded-r opacity-50 cursor-pointer text-2xs hover:opacity-75">
                <fa-icon icon="arrow-right" />
              </button>
            </div>
            <div v-if="showBookmarksList" class="absolute top-0 w-48 mt-8 origin-center rounded-md shadow-lg">
              <div class="bg-gray-900 bg-opacity-75 rounded-md ring-1 ring-black ring-opacity-5">
                <div class="">
                  <template v-for="bookmark in bookmarks">
                    <div
                      @click.prevent="viewBookmark(bookmark)"
                      :class="{ 'bg-gray-800 font-bold' : bookmark.name === lastActiveBookmarkName }"
                      class="block px-2 py-1 leading-5 text-gray-400 rounded text-2xs hover:bg-gray-800 hover:text-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-900">
                      <fa-icon
                        icon="camera-movie"
                        size="2xs"
                        class="mr-1 opacity-0 fa-fw"
                        :class="{ 'text-teal-500 !opacity-100' : bookmark.name === lastActiveBookmarkName }"
                        />
                      {{ bookmark.name }}
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="w-8 right-controls">
        </div>
  
      </div>
  
    </div>
  </template>
  <script>
  import menuMixin from './menuMixin.js'
  export default {
    name: 'viewer-top-controls',
    mixins: [menuMixin],
    props: {
      bookmarks: {
        type: Array,
        required: true
      },
      activeBookmark: {
        type: Object,
        required: false
      },
      lastActiveBookmark: {
        type: Object,
        required: false
      }
    },
    data () {
      return {
        showBookmarksList: false
      }
    },
    computed: {
      activeBookmarkName () {
        if (this.activeBookmark !== null) return this.activeBookmark.name
        return 'Free View'
      },
      lastActiveBookmarkName () {
        if (this.lastActiveBookmark !== null) return this.lastActiveBookmark.name
        return null
      },
      showBookmarkSwitcher () {
        return this.bookmarks.length > 0
      },
      hasMultipleBookmarks () {
        return this.bookmarks.length > 1
      },
      hasActiveBookmark () {
        return this.activeBookmark !== null
      }
    },
    methods: {
      close () {
        this.showBookmarksList = false
      },
      toggleBookmarksListOrView () {
        if(this.hasMultipleBookmarks) this.toggleBookmarksList()
        else {
          this.viewBookmark(this.bookmarks[0])
        }
      },
      toggleBookmarksList () {
        this.attachOpenListener()
        this.showBookmarksList = !this.showBookmarksList
        if (this.showBookmarksList === false) {
          this.detachOpenListener()
        }
      },
      togglePickMode () {
        this.$emit('toggle-pick-mode')
      },
      toggle (key) {
        this.$bus.$emit('editor:panel:toggle', key)
      },
      nextBookmark () {
        this.showBookmarksList = false
        this.$emit('view-next-bookmark')
      },
      prevBookmark () {
        this.showBookmarksList = false
        this.$emit('view-prev-bookmark')
      },
      viewBookmark (bookmark) {
        this.showBookmarksList = false
        this.$emit('view-bookmark', bookmark)
      }
    }
  }
  </script>